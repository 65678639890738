import React, { useState, memo, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Transition } from 'react-transition-group';

function NavCategory({ name, links, defaultExpand }) {
  const [ expand, setExpand ] = useState(defaultExpand);

  const linkItems = useMemo(() => {
    if (expand) {
      return links.map((link, index) => {
        if (link.hasOwnProperty('outboundPath')) {
          return (
            <a
              key={ link.name }
              className='drop-nav__links'
              id={ `drop-nav-${ index }` }
              target='_blank'
              rel='noopener noreferrer'
              href={ link.outboundPath }>
              { link.name }
            </a>
          )
        } else {
          return (
            <NavLink
              key={ link.name }
              className='drop-nav__links'
              id={ `drop-nav-${ index }` }
              exact
              to={ link.path }
              onClick={ link.handler }
              activeClassName='drop-nav__links--active'>
              { link.name }
            </NavLink>
          )
        }
      })
    } else {
      return [];
    }
  }, [ links, expand ]);

  return (
    <div className='collapsible'>
      { links.length > 1 &&
        <h2 
          className='drop-nav__category__title' 
          onClick={ () => setExpand(expand => !expand) }>
          { name }
          <span className={ expand ? 'stats__table__chevron' : 'stats__table__chevron down' }></span>
        </h2>
      }

      { links.length > 1 ? (
        <Transition
          in={ expand }
          className='nav'
          timeout={ 250 }>
          <>
            { linkItems }
          </>
        </Transition>
      ) : (
        <NavLink
          className='drop-nav__links'
          exact
          to={ links[0].path }
          onClick={ links[0].handler }
          activeClassName='drop-nav__links--active'>
          { links[0].name }
        </NavLink>
      ) }
    </div>
  );
}

export default memo(NavCategory);
