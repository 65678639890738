import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

const SportSelection = lazy(() => import('./SportSelection'));
// sport landing pages
const CrossCountry = lazy(() => import('./sports/CrossCountry'));
const Equestrian = lazy(() => import('./sports/Equestrian'));
const Football = lazy(() => import('./sports/Football'));
const FootballRecap = lazy(() => import('./sports/FootballRecap'));
const MensBasketball = lazy(() => import('./sports/MensBasketball'));
const Track = lazy(() => import('./sports/Track'));
const Volleyball = lazy(() => import('./sports/Volleyball'));
const WomensBasketball = lazy(() => import('./sports/WomensBasketball'));
const WomensSoccer = lazy(() => import('./sports/WomensSoccer'));
const Baseball = lazy(() => import('./sports/Baseball'));
const Softball = lazy(() => import('./sports/Softball'));
// shared sub pages
const CollegeScores = lazy(() => import('./CollegeScores'));
const FanPrize = lazy(() => import('./FanPrize'));
const HeatSheet = lazy(() => import('./HeatSheet'));
const JoinWiFi = lazy(() => import('./JoinWiFi'));
const Promos = lazy(() => import('./Promos'));
const PromotionConfirmation = lazy(() => import('./promotions/PromotionConfirmation'));
const Rosters = lazy(() => import('./Rosters'));
const Rudys = lazy(() => import('./Rudys'));
const RudysConfirmation = lazy(() => import('./offer/RudysConfirmaiton'));
const Rules = lazy(() => import('./promotions/Rules'));
const Schedule = lazy(() => import('./Schedule'));
const Stats = lazy(() => import('./Stats'));
const Suggestions = lazy(() => import('./Suggestions'));
const TicketConfirmation = lazy(() => import('./ticket-giveaway/TicketConfirmation'));
const TicketContest = lazy(() => import('./TicketContest'));
const Traditions = lazy(() => import('./Traditions'));

// import Poll from './Poll';
// import ScratchOff from './ScratchOff';
// import Shop from './shop/Shop';
// import ShopCoupon from './shop/ShopCoupon';


function Routes({ pages }) {
  const fieldsOnlyRoutes = [
    // generic paths
    { path: '/', comp: SportSelection, fields: pages.sportSelection },
    { path: '/suggestions', comp: Suggestions, fields: pages.suggestions },
    { path: '/join-wifi', comp: JoinWiFi, fields: pages.joinwifi },
    { path: '/songs-yells', comp: Traditions, fields: pages.traditions },

    // fb paths
    { path: '/football', comp: Football, fields: {} },
    { path: '/football/recap', comp: FootballRecap, fields: pages.schedule },
    { path: '/football/ford', comp: FanPrize, fields: pages.promotions },
    { path: '/football/rosters', comp: Rosters, fields: pages.rosters },
    { path: '/football/college-scores', comp: CollegeScores, fields: pages.scoreboards },
    { path: '/football/stats', comp: Stats, fields: pages.stats },
    { path: '/football/tickets', comp: TicketContest, fields: pages.tickets },
    { path: '/football/promos', comp: Promos, fields: pages.promos },
    { path: '/football/schedule', comp: Schedule, fields: pages.schedule },
    { path: '/football/suggestions', comp: Suggestions, fields: pages.suggestions },
    { path: '/football/join-wifi', comp: JoinWiFi, fields: pages.joinwifi },
    { path: '/football/ford/confirmation', comp: PromotionConfirmation, fields: pages.promotions },
    { path: '/football/tickets/confirmation', comp: TicketConfirmation, fields: pages.tickets },

    // volleyball paths
    { path: '/volleyball', comp: Volleyball, fields: {} },
    { path: '/volleyball/rosters', comp: Rosters, fields: pages.rosters },
    { path: '/volleyball/stats', comp: Stats, fields: pages.stats },
    { path: '/volleyball/songs-yells', comp: Traditions, fields: pages.traditions },
    { path: '/volleyball/schedule', comp: Schedule, fields: pages.schedule },
    { path: '/volleyball/promos', comp: Promos, fields: pages.promos },
    { path: '/volleyball/suggestions', comp: Suggestions, fields: pages.suggestions },
    { path: '/volleyball/tickets', comp: TicketContest, fields: pages.tickets },
    { path: '/volleyball/tickets/confirmation', comp: TicketConfirmation, fields: pages.tickets },

    // men's basketball paths
    { path: '/mbb', comp: MensBasketball, fields: {} },
    { path: '/mbb/tickets', comp: TicketContest, fields: pages.tickets },
    { path: '/mbb/rosters', comp: Rosters, fields: pages.rosters },
    { path: '/mbb/stats', comp: Stats, fields: pages.stats },
    { path: '/mbb/college-scores', comp: CollegeScores, fields: pages.scoreboards },
    { path: '/mbb/promos', comp: Promos, fields: pages.promos },
    { path: '/mbb/rudys', comp: Rudys, fields: pages.rudys },
    { path: '/mbb/schedule', comp: Schedule, fields: pages.schedule },
    { path: '/mbb/suggestions', comp: Suggestions, fields: pages.suggestions },
    { path: '/mbb/tickets/confirmation', comp: TicketConfirmation, fields: pages.tickets },
    { path: '/mbb/rudys/confirmation', comp: RudysConfirmation, fields: pages.rudys },

    // women's basketball paths
    { path: '/wbb', comp: WomensBasketball, fields: {} },
    { path: '/wbb/rosters', comp: Rosters, fields: pages.rosters },
    { path: '/wbb/stats', comp: Stats, fields: pages.stats },
    { path: '/wbb/college-scores', comp: CollegeScores, fields: pages.scoreboards },
    { path: '/wbb/schedule', comp: Schedule, fields: pages.schedule },
    { path: '/wbb/promos', comp: Promos, fields: pages.promos },
    { path: '/wbb/suggestions', comp: Suggestions, fields: pages.suggestions },
    { path: '/wbb/tickets', comp: TicketContest, fields: pages.tickets },
    { path: '/wbb/tickets/confirmation', comp: TicketConfirmation, fields: pages.tickets },

    // baseball paths
    { path: '/baseball', comp: Baseball, fields: {} },
    { path: '/baseball/tickets', comp: TicketContest, fields: pages.tickets },
    { path: '/baseball/rosters', comp: Rosters, fields: pages.rosters },
    { path: '/baseball/stats', comp: Stats, fields: pages.stats },
    { path: '/baseball/college-scores', comp: CollegeScores, fields: pages.scoreboards },
    { path: '/baseball/promos', comp: Promos, fields: pages.promos },
    { path: '/baseball/rudys', comp: Rudys, fields: pages.rudys },
    { path: '/baseball/schedule', comp: Schedule, fields: pages.schedule },
    { path: '/baseball/suggestions', comp: Suggestions, fields: pages.suggestions },
    { path: '/baseball/tickets/confirmation', comp: TicketConfirmation, fields: pages.tickets },
    { path: '/baseball/rudys/confirmation', comp: RudysConfirmation, fields: pages.rudys },

    // softball paths
    { path: '/softball', comp: Softball, fields: {} },
    { path: '/softball/rosters', comp: Rosters, fields: pages.rosters },
    { path: '/softball/stats', comp: Stats, fields: pages.stats },
    { path: '/softball/college-scores', comp: CollegeScores, fields: pages.scoreboards },
    { path: '/softball/schedule', comp: Schedule, fields: pages.schedule },
    { path: '/softball/promos', comp: Promos, fields: pages.promos },
    { path: '/softball/suggestions', comp: Suggestions, fields: pages.suggestions },
    { path: '/softball/tickets', comp: TicketContest, fields: pages.tickets },
    { path: '/softball/tickets/confirmation', comp: TicketConfirmation, fields: pages.tickets },

    // women's soccer paths
    { path: '/womens-soccer', comp: WomensSoccer, fields: pages.womensSoccer },
    { path: '/womens-soccer/rosters', comp: Rosters, fields: pages.rosters },
    { path: '/womens-soccer/stats', comp: Stats, fields: pages.stats },
    { path: '/womens-soccer/songs-yells', comp: Traditions, fields: pages.traditions },
    { path: '/womens-soccer/schedule', comp: Schedule, fields: pages.schedule },
    { path: '/womens-soccer/promos', comp: Promos, fields: pages.promos },
    { path: '/womens-soccer/suggestions', comp: Suggestions, fields: pages.suggestions },
    { path: '/womens-soccer/tickets', comp: TicketContest, fields: pages.tickets },
    { path: '/womens-soccer/tickets/confirmation', comp: TicketConfirmation, fields: pages.tickets },

    // cross country paths
    { path: '/cross-country', comp: CrossCountry, fields: pages.crossCountry },
    { path: '/cross-country/rosters', comp: Rosters, fields: pages.rosters },
    { path: '/cross-country/songs-yells', comp: Traditions, fields: pages.traditions },
    { path: '/cross-country/schedule', comp: Schedule, fields: pages.schedule },
    { path: '/cross-country/suggestions', comp: Suggestions, fields: pages.suggestions },

    // track paths
    { path: '/track', comp: Track, fields: {} },
    { path: '/track/rosters', comp: Rosters, fields: pages.rosters },
    { path: '/track/heat-sheets', comp: HeatSheet, fields: pages.heatSheet },
    { path: '/track/schedule', comp: Schedule, fields: pages.schedule },
    { path: '/track/promos', comp: Promos, fields: pages.promos },
    { path: '/track/suggestions', comp: Suggestions, fields: pages.suggestions },

    // equestrian paths
    { path: '/equestrian', comp: Equestrian, fields: pages.equestrian },
    { path: '/equestrian/rosters', comp: Rosters, fields: pages.rosters },
    { path: '/equestrian/songs-yells', comp: Traditions, fields: pages.traditions },
    { path: '/equestrian/schedule', comp: Schedule, fields: pages.schedule },
    { path: '/equestrian/promos', comp: Promos, fields: pages.promos },
    { path: '/equestrian/suggestions', comp: Suggestions, fields: pages.suggestions },

    // { path: '/scratch-off', comp: ScratchOff, fields: pages.scratch },
    // { path: '/traditions', comp: Traditions, fields: pages.traditions },
    // { path: '/traditions/deal', comp: Shop, fields: pages.shop },
    // { path: '/traditions/deal/coupon', comp: ShopCoupon, fields: pages.shop },
    // { path: '/poll', comp: Poll, fields: pages.poll },
    // { path: '/heat-sheets', comp: HeatSheet, fields: pages.heatSheet },
  ];

  const rulesRoutes = [
    { path: '/football/ford/rules', sponsor: pages.promotions.promotions_sponsor, text: pages.promotions.promotions_contest_rules },
    { path: '/mbb/rudys/rules', sponsor: pages.rudys.rudys_sponsor, text: pages.rudys.rudys_rules},
    { path: '/football/tickets/rules', sponsor: pages.tickets.ticket_sponsor_image, text: null },
    { path: '/mbb/tickets/rules', sponsor: pages.tickets.ticket_sponsor_image, text: null },
    { path: '/wbb/tickets/rules', sponsor: pages.tickets.ticket_sponsor_image, text: null },
    { path: '/volleyball/tickets/rules', sponsor: pages.tickets.ticket_sponsor_image, text: null },
    { path: '/womens-soccer/tickets/rules', sponsor: pages.tickets.ticket_sponsor_image, text: null },
    // { path: '/scratch-off/rules', sponsor: pages.scratch.scratch_sponsor, text: pages.scratch.scratch_rules }
  ];

  return (
    <Suspense fallback={ <img className='loading__logo animated pulse infinite' src={ `${ process.env.PUBLIC_URL }/img/white-logo.png` } alt='TAMU' /> }>
      <Switch>
        { fieldsOnlyRoutes.map(option => {
          const Comp = option.comp;
          return (
            <Route
              exact
              key={ option.path }
              path={ `${ process.env.PUBLIC_URL }${ option.path }` }
              render={(props) => <Comp { ...props } fields={ option.fields } /> } />
          )
        })}

        { rulesRoutes.map(option => {
          return (
            <Route
              exact
              key={ option.path }
              path={ `${ process.env.PUBLIC_URL }${ option.path }` }
              render={ (props) => <Rules { ...props } sponsor={ option.sponsor } text={ option.text } /> } />
          )
        }) }
        
        <Route
          render={ (props) => <SportSelection {...props} fields={ pages.sportSelection } sched={ pages.schedule } /> } />
      </Switch>
    </Suspense>
  )
}

export default React.memo(Routes, (prevProps, nextProps) => prevProps.pages === nextProps.pages);
