import React from 'react';
import globalHook from 'use-global-hook';

import * as actions from './actions';

const initialState = {
  selectedSport: 'baseball',
  baseballFields: null,
  crossCountryFields: null,
  equestrianFields: null,
  footballFields: null,
  mbbFields: null,
  softballFields: null,
  trackFields: null,
  volleyballFields: null,
  wbbFields: null,
  womensSoccerFields: null,
  allSportsLoaded: false,
  pages: null,
  allPagesLoaded: false
};

const useGlobal = globalHook( React, initialState, actions );

export default useGlobal;
