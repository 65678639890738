import React from 'react';
import PropTypes from 'prop-types';

import CrossCountryNav from './sport-navs/CrossCountryNav';
import EquestrianNav from './sport-navs/EquestrianNav';
import FootballNav from './sport-navs/FootballNav';
import MbbNav from './sport-navs/MbbNav';
import TrackNav from './sport-navs/TrackNav';
import VolleyballNav from './sport-navs/VolleyballNav';
import WbbNav from './sport-navs/WbbNav';
import WomensSoccerNav from './sport-navs/WomensSoccerNav';
import SoftballNav from './sport-navs/SoftballNav';
import BaseballNav from './sport-navs/BaseballNav';

function Menu({ sport, menuOpen, closeMenu, handleMenuToggle }) {
  let SportNav = FootballNav;
  
  switch (sport) {
    case 'football':
      SportNav = FootballNav;
      break;
    case 'mbb':
      SportNav = MbbNav;
      break;
    case 'wbb':
      SportNav = WbbNav;
      break;
    case 'volleyball':
      SportNav = VolleyballNav;
      break;
    case 'equestrian':
      SportNav = EquestrianNav;
      break;
    case 'wm-soccer':
      SportNav = WomensSoccerNav;
      break;
    case 'cross-country':
      SportNav = CrossCountryNav;
      break;
    case 'track':
      SportNav = TrackNav;
      break;
    case 'softball':
      SportNav = SoftballNav;
      break;
    case 'baseball':
      SportNav = BaseballNav;
      break;
    default:
      break;
  }

  return (
    <div className='header__menu'>
      <div id='nav-burger' className={ menuOpen ? 'open' : 'closed' } onClick={ handleMenuToggle }>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <SportNav open={ menuOpen } handler={ closeMenu } />
    </div>
  );
}

Menu.propTypes = {
  sport: PropTypes.string.isRequired
}

export default Menu;
