import React from 'react';
import { NavLink } from 'react-router-dom';

import NavCategory from '../drop-nav/NavCategory';

function BaseballNav({ open, handler }) {
  const gamedayLinks = [
    { name: 'Home', path: `${ process.env.PUBLIC_URL }/baseball`, handler: handler },
    { name: 'Rosters', path: `${ process.env.PUBLIC_URL }/baseball/rosters`, handler: handler },
    { name: 'Player Stats', path: `${ process.env.PUBLIC_URL }/baseball/stats`, handler: handler },
    { name: 'Schedule', path: `${ process.env.PUBLIC_URL }/baseball/schedule`, handler: handler },
  ];

  const giveawayLinks = [
    { name: 'Win Tickets', path: `${ process.env.PUBLIC_URL }/baseball/tickets`, handler: handler },
    { name: 'Promos', path: `${ process.env.PUBLIC_URL }/baseball/promos`, handler: handler }
  ];

  const prideLinks = [
    // { name: 'Songs & Yells', path: `${ process.env.PUBLIC_URL }/songs-yells`, handler: handler },
  ];

  const supportLinks = [
    { name: 'Suggestion Box', path: `${ process.env.PUBLIC_URL }/baseball/suggestions`, handler: handler }
  ];

  return (
    <div className={ open ? 'drop-nav drop-nav__open' : 'drop-nav drop-nav__closed' }>
      <NavLink
        className='drop-nav__links'
        exact
        to={ `${ process.env.PUBLIC_URL }/` }
        onClick={ () => handler() }
        activeClassName='drop-nav__links--active'>
        OTHER SPORTS
      </NavLink>

      { gamedayLinks.length > 0 &&
        <NavCategory
          name='GAME DAY'
          links={ gamedayLinks } />
      }

      { giveawayLinks.length > 0 &&
        <NavCategory
          name='GIVEAWAYS'
          links={ giveawayLinks } />
      }

      { prideLinks.length > 0 &&
        <NavCategory
          name='AGGIE PRIDE'
          links={ prideLinks } />
      }

      { supportLinks.length > 0 &&
        <NavCategory
          name='SUPPORT'
          links={ supportLinks } />
      }

    </div>
  );
}

export default BaseballNav;
