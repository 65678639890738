import axios from 'axios';

export const getAllPages = async (store, request = axios) => {
  if (!store.state.allPagesLoaded) {
    try {
      let pagesResult = await request.get(`http://ha.12thmanlive.com/wp-json/acf/v3/pages?per_page=35`);
      let pages = {};

      pagesResult.data.forEach(page => {
        if (page.acf.hasOwnProperty('countdown_header')) {
          pages.countdown = page.acf;
        } else if (page.acf.hasOwnProperty('preview_header_image')) {
          pages.preview = page.acf;
        } else if (page.acf.hasOwnProperty('traditions_header_image')) {
          pages.traditions = page.acf;
        } else if (page.acf.hasOwnProperty('rosters_header_image')) {
          pages.rosters = page.acf;
        } else if (page.acf.hasOwnProperty('stats_update_banner')) {
          pages.stats = page.acf;
        } else if (page.acf.hasOwnProperty('poll_header_image')) {
          pages.poll = page.acf;
        } else if (page.acf.hasOwnProperty('promotions_header_image')) {
          pages.promotions = page.acf;
        } else if (page.acf.hasOwnProperty('join_wifi_sponsor')) {
          pages.joinwifi = page.acf;
        } else if (page.acf.hasOwnProperty('chat_sponsor')) {
          pages.chat = page.acf;
        } else if (page.acf.hasOwnProperty('suggestions_sponsor')) {
          pages.suggestions = page.acf;
        } else if (page.acf.hasOwnProperty('scoreboards_header_image')) {
          pages.scoreboards = page.acf;
        } else if (page.acf.hasOwnProperty('ticket_sponsor_image')) {
          pages.tickets = page.acf;
        } else if (page.acf.hasOwnProperty('fancam_sponsor')) {
          pages.fancam = page.acf;
        } else if (page.acf.hasOwnProperty('promos_sponsor')) {
          pages.promos = page.acf;
        } else if (page.acf.hasOwnProperty('shop_coupon')) {
          pages.shop = page.acf;
        } else if (page.acf.hasOwnProperty('heat_sheet_sponsor_image')) {
          pages.heatSheet = page.acf;
        } else if (page.acf.hasOwnProperty('rudys_header')) {
          pages.rudys = page.acf;
        } else if (page.acf.hasOwnProperty('flash_result_sponsor_image')) {
          pages.flashResult = page.acf;
        } else if (page.acf.hasOwnProperty('football_image')) {
          pages.sportSelection = page.acf;
        } else if (page.acf.hasOwnProperty('scratch_header')) {
          pages.scratch = page.acf;
        } else if (page.acf.hasOwnProperty('schedule_sponsor')) {
          pages.schedule = page.acf;
        }
      });

      store.setState({ pages, allPagesLoaded: true });
    } catch (error) {
      console.log('error fetching pages: ', error);
    }
  }
};
