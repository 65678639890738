import React, { useState, useEffect } from 'react';
import camelCase from 'camelcase';
import { Link, useLocation } from 'react-router-dom';
import Menu from './Menu';

function Header({ showLogo, showBurger }) {
  const [ sport, setSport ] = useState('');
  const [ menuOpen, setMenuOpen ] = useState(false);
  const [ showBurg, setShowBurg ] = useState(showBurger);
  const [ headerLink, setHeaderLink ] = useState(`${ process.env.PUBLIC_URL }/`);
  const location = useLocation();

  // set home path on header logo click
  useEffect(() => {
    if (location.pathname === '/') {
      setHeaderLink(`${ process.env.PUBLIC_URL }/`);
      setMenuOpen(false);
      setShowBurg(false);
    } else {
      const pathSplit = location.pathname.split('/');
      const sportPath = camelCase(pathSplit[ 1 ]);
      setShowBurg(true);
      setSport(sportPath);

      switch (sportPath) {
        case 'womensSoccer':
          setHeaderLink(`${ process.env.PUBLIC_URL }/womens-soccer`);
          break;
        case 'crossCountry':
          setHeaderLink(`${ process.env.PUBLIC_URL }/cross-country`);
          break;
        default:
          setHeaderLink(`${ process.env.PUBLIC_URL }/${ sportPath }`);
          break;
      }
    }
  }, [ location.pathname ]);

  return (
    <div id='header' className='header'>
      <Link 
        className={ showLogo ? 'header__link' : 'header__link--full' } 
        to={ headerLink }
        onClick={ () => setMenuOpen(false) }>

        { showLogo && <img className='header__link__logo' src={ `${process.env.PUBLIC_URL}/img/white-logo.png` } alt='TAMU' /> }

        <div className='header__default animated fadeInDown'>12th Man Live</div>
      </Link>
      { showBurg && (
        <Menu 
          sport={ sport } 
          menuOpen={ menuOpen } 
          handleMenuToggle={ () => setMenuOpen(menuOpen => !menuOpen) }
          closeMenu={ () => setMenuOpen(false) }  /> 
      )}

    </div>
  );
}

export default Header;
