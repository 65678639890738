import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import history from '../utils/history';
import ReactGA from 'react-ga';
import '../styles/App.scss';
import useGlobal from '../store';

import Header from '../components/Header';
import Routes from './Routes';

function App() {
  const [ globalState, globalActions ] = useGlobal();
  const { allPagesLoaded, allSportsLoaded, pages } = globalState;

  useEffect(() => {
    globalActions.sports.getAllSports();
    globalActions.pages.getAllPages();

    ReactGA.initialize('UA-72166031-23');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [ globalActions.sports, globalActions.pages ]);

  if (allPagesLoaded && allSportsLoaded) {
    return (
      <Router basename={ process.env.PUBLIC_URL } history={ history } >
        <div className='app'>
          <Header
            showLogo={ true }
            showBurger={ true } />

          <div id='header-gap'></div>

          <Routes pages={ pages } />
        </div>
      </Router>
    );
  } else {
    return (
      <div className='error-loading'>
        <div className='app'>
          <div id='header' className='header'>
            <div className='header__link--full'>

              <div className='header__default animated fadeInDown'>
                12th Man Live
              </div>
            </div>
          </div>

          <div id='header-gap'></div>

          <img 
            className='loading__logo animated pulse infinite' 
            src={ `${ process.env.PUBLIC_URL }/img/white-logo.png` } 
            alt='TAMU' />
        </div>
      </div>
    );
  }
}
// App.whyDidYouRender = true;
export default App;
