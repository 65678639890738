import axios from 'axios';

export const getAllSports = async (store, request = axios) => {
  if (!store.state.allSportsLoaded) {
    let newState = {};

    try {
      const response = await request.get(`http://ha.12thmanlive.com/wp-json/acf/v3/sports?per_page=35`);
      const allSports = response.data;

      allSports.forEach(sport => {
        let ident = sport.acf.abbreviation;

        if (ident === 'baseball') {
          newState.baseballFields = sport.acf;
        } else if (ident === 'softball') {
          newState.softballFields = sport.acf;
        } else if (ident === 'crossCountry') {
          newState.crossCountryFields = sport.acf;
        } else if (ident === 'volleyball') {
          newState.volleyballFields = sport.acf;
        } else if (ident === 'equestrian') {
          newState.equestrianFields = sport.acf;
        } else if (ident === 'womensSoccer') {
          newState.womensSoccerFields = sport.acf;
        } else if (ident === 'wbb') {
          newState.wbbFields = sport.acf;
        } else if (ident === 'mbb') {
          newState.mbbFields = sport.acf;
        } else if (ident === 'football') {
          newState.footballFields = sport.acf;
        } else if (ident === 'track') {
          newState.trackFields = sport.acf;
        }
      });

      newState.allSportsLoaded = true;
      store.setState({ ...newState });
    } catch (error) {
      console.log('error fetching sports ', error);
    }
  }
  
};
